import {Layout} from "../components/Layout";
import React from 'react';
import {graphql} from "gatsby";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {CareersHero} from "../components/CareersHero";
import {BackgroundLine} from "../components/BackgroundLine";
import {CareersBenefits} from "../components/CareersBenefits";
import {BulletPoint} from "../components/BulletPoint";
import {CareersOpportunities} from "../components/CareersOpportunities";
import {CareersJobs} from "../components/CareersJobs";
import {RecruitmentProcess} from "../components/RecruitmentProcess";
import {Testimonial} from "../components/Testimonial";
import * as styles from './careers.module.scss';
import {CareersContactPeopleBlock} from "../components/CareersContactPeopleBlock";
import {useIntl} from 'gatsby-plugin-react-intl';
import {createSeoData} from "../functions/createSeoData";
import {MarkdownRenderer} from "../components/MarkdownRenderer";

export const query = graphql`query Careers($locale: String!) {
    page: strapiCareersPage(locale: {eq: $locale}) {
        title
        heroTitle
        heroContent {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        heroImages {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 340
                        height: 590
                        placeholder: NONE
                        transformOptions: {cropFocus: NORTHWEST}
                        layout: FIXED
                    )
                }
            }
        }
        benefitsTitle
        benefitsContent {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        benefits {
            title
            content {
                data {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            icon {
                localFile {
                    url
                    childImageSharp {
                        gatsbyImageData(width: 52, height: 52, placeholder: NONE, layout: FIXED)
                    }
                }
            }
        }
        opportunitiesTitle
        opportunitiesContent {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        opportunities {
            title
            content {
                data {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            image {
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 480, height: 690, placeholder: NONE, layout: FIXED)
                    }
                }
            }
        }
        jobsTitle
        jobsContent {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        recruitmentProcessTitle
        recruitmentProcessContent {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        recruitmentProcessSteps {
            title
            content {
                data {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
        heroReference {
            content
            heroImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 600, height: 600, placeholder: NONE, layout: FIXED)
                    }
                }
            }
            personName
            personPosition
            personCountry
        }
        contactTitle
        contactContent {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        contactPersons {
            name
            position
            email
            phone
            bigImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 600
                            height: 600
                            placeholder: NONE
                            transformOptions: {cropFocus: NORTH}
                            layout: FIXED
                        )
                    }
                }
            }
        }
        seo {
            ...Seo
        }
    }
    jobs: allStrapiJobOffer(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                name
                url
                description {
                    data {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            }
        }
    }
}
`;

export default function CareersPage({data, pageContext}) {
    const intl = useIntl();
    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={intl.formatMessage({id: 'menu.careers'})}
                   jobsCount={pageContext.jobsCount}
                   seo={createSeoData(data.page.seo)}
                   companyData={pageContext.companyData}
                   background={<>
                       <BackgroundLine mobileOn={false}/>
                       <BackgroundLine position={"flexion"}/>
                   </>}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper>
            <CareersHero breadcrumbTitle={data.page.title}
                         title={data.page.heroTitle}
                         firstImage={data.page.heroImages[0].localFile.childImageSharp}
                         secondImage={data.page.heroImages[1].localFile.childImageSharp}
                         content={data.page.heroContent.data}
            />
            <CareersBenefits title={data.page.benefitsTitle} content={data.page.benefitsContent.benefitsContent}>
                {data.page.benefits ? data.page.benefits.map((data, index) => {
                    return <BulletPoint image={data.icon.localFile}
                                        appearance="vertical"
                                        key={index}
                                        title={data.title}>
                        <MarkdownRenderer content={data.content.data}/>
                    </BulletPoint>
                }) : undefined}
            </CareersBenefits>
            <CareersOpportunities
                title={data.page.opportunitiesTitle}
                content={data.page.opportunitiesContent.data}
                opportunities={data.page.opportunities}
            />
            {displayHeroReference(data.page.heroReference)}
            <RecruitmentProcess title={data.page.recruitmentProcessTitle}
                                content={data.page.recruitmentProcessContent.data}
                                steps={data.page.recruitmentProcessSteps}
            />
        </PageMainColumnWrapper>
        <CareersJobs jobs={data.jobs.edges.map(x => x.node)}
                     title={data.page.jobsTitle}
                     content={data.page.jobsContent}
        />
        <PageMainColumnWrapper>
            <CareersContactPeopleBlock title={data.page.contactTitle}
                                       content={data.page.contactContent.data}
                                       people={data.page.contactPersons}/>
        </PageMainColumnWrapper>
    </Layout>
}

function displayHeroReference(ref) {
    if (ref) {
        return <Testimonial
            appearance="secondHero"
            className={styles.reference}
            description={ref.content}
            image={ref.heroImage ? ref.heroImage.localFile.childImageSharp : undefined}
            person={{
                name: ref.personName,
                position: ref.personPosition,
                country: ref.personCountry
            }}
        />;
    }
}
