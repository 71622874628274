import * as styles from './CareersOpportunities.module.scss';
import React, {useState} from 'react';
import classNames from 'classnames';
import {BlockTitle} from "../BlockTitle";
import {Typography} from "../Typography";
import {MarkdownContent} from "../MarkdownContent";
import {ClippedImage} from "../ClippedImage";
import {componentRef} from "../../functions/componentRef";
import {MarkdownRenderer} from "../MarkdownRenderer";
import {BlockLead} from "../BlockLead";

export function CareersOpportunities({title, content, opportunities, image, className}) {
    const [selected, setSelected] = useState(0);

    const currentOpportunity = opportunities[selected];

    return <section className={classNames(className, styles.root)} {...componentRef('career-opport')}>
        <div className={styles.contentWrapper}>
            <BlockTitle tag="h2" className={styles.title}>{title}</BlockTitle>
            <BlockLead content={content}/>
        </div>
        <div className={styles.opportunitiesWrapper}>
            <div className={styles.opportunities} {...componentRef.subcomponent('career-opport-list')}>
                {opportunities.map((x, index) => {
                    return <Opportunity key={index}
                                        data-index={index}
                                        onSelected={() => {
                                            setSelected(index);
                                        }}
                                        isActive={selected === index}
                                        title={x.title}
                                        content={x.content.data}/>
                })}
            </div>
            <div className={styles.imageWrapper}>
                <ClippedImage image={currentOpportunity.image.localFile.childImageSharp} className={styles.image}
                              slopeHeight="240px"/>
            </div>
        </div>
    </section>
}

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
function Opportunity({isActive, title, content, onSelected}) {
    const classes = classNames(styles.opportunity, isActive && styles.opportunityActive);
    return <article className={classes} onClick={onSelected} {...componentRef('opport-item')}>
        <h1 className={styles.opportunityTitle} {...componentRef.subcomponent('opport-title')}>{title}</h1>
        <MarkdownContent component={Typography} content={content} className={styles.opportunityContent}/>
    </article>
}

