import React from 'react';
import * as styles from './CareersContactPeople.module.scss';
import {EmailLink} from "../EmailLink";
import {PhoneNumberLink} from "../PhoneNumberLink";
import {GatsbyImage} from "gatsby-plugin-image";
import classNames from 'classnames';
import {componentRef} from "../../functions/componentRef";

export function CareersContactPeople({firstPerson, secondPerson}) {
    return (
        <div className={styles.root}>
            {renderPersonData(firstPerson)}
            {renderPersonData(secondPerson)}
            {firstPerson.bigImage && <GatsbyImage
                image={firstPerson.bigImage.localFile.childImageSharp.gatsbyImageData}
                alt={"Careers"}
                className={classNames(styles.personImage, styles.first)}/>}

            {secondPerson.bigImage && <GatsbyImage
                image={secondPerson.bigImage.localFile.childImageSharp.gatsbyImageData}
                alt={"Careers"}
                className={classNames(styles.personImage, styles.second)}/>}
        </div>
    );
}

function renderPersonData(person) {
    return <PersonData name={person.name}
                       position={person.position}
                       email={person.email}
                       phone={person.phone}
    />;
}

function PersonData({name, position, email, phone}) {
    return <article className={styles.personData} {...componentRef('contact-person')}>
        <h1 className={styles.name} {...componentRef.subcomponent('contact-name')}>{name}</h1>
        <span className={styles.position} {...componentRef.subcomponent('contact-position')}>{position}</span>
        <EmailLink email={email} className={styles.email} {...componentRef.subcomponent('contact-email')}/>
        <PhoneNumberLink number={phone} className={styles.phone} {...componentRef.subcomponent('contact-phone')}/>
    </article>
}
