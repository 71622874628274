// extracted by mini-css-extract-plugin
export var contentWrapper = "CareersOpportunities-module--contentWrapper--e56f9";
export var image = "CareersOpportunities-module--image--cbf5a";
export var imageWrapper = "CareersOpportunities-module--imageWrapper--9a396";
export var opportunities = "CareersOpportunities-module--opportunities--187ae";
export var opportunitiesWrapper = "CareersOpportunities-module--opportunitiesWrapper--ffcba";
export var opportunity = "CareersOpportunities-module--opportunity--60a9c";
export var opportunityActive = "CareersOpportunities-module--opportunityActive--df8d4";
export var opportunityContent = "CareersOpportunities-module--opportunityContent--98c22";
export var opportunityTitle = "CareersOpportunities-module--opportunityTitle--f7b29";
export var title = "CareersOpportunities-module--title--20ccb";