import * as styles from './CareersContactPeopleBlock.module.scss';
import React from 'react';
import {BlockTitle} from "../BlockTitle";
import {Typography} from "../Typography";
import {CareersContactPeople} from "../CareersContactPeople";
import {componentRef} from "../../functions/componentRef";
import {MarkdownContent} from "../MarkdownContent";
import {MarkdownRenderer} from "../MarkdownRenderer";

export function CareersContactPeopleBlock({title, content, people}) {
    return <section className={styles.root} {...componentRef('career-contact')}>
        <div className={styles.contentWrapper}>
            <BlockTitle tag="h2" className={styles.title}>{title}</BlockTitle>
            <MarkdownRenderer bodyVariant="lead" className={styles.content} content={content}/>
        </div>
        <CareersContactPeople firstPerson={people[0]} secondPerson={people[1]}/>
    </section>
}
