import React from 'react';
import * as styles from './CareersBenefits.module.scss';
import classNames from 'classnames';
import {Typography} from "../Typography";
import {BlockTitle} from "../BlockTitle";
import {componentRef} from "../../functions/componentRef";

export function CareersBenefits({className, children, title, content, ...otherProps}) {
    return <section className={classNames(className, styles.root)} {...otherProps} {...componentRef('career-benefit')}>
        <div className={styles.contentWrapper}>
            <BlockTitle tag="h2" className={styles.title}>{title}</BlockTitle>
            <Typography>{content}</Typography>
        </div>
        <div className={styles.points} {...componentRef.subcomponent('career-benefit-points')}>
            {React.Children.map(children, child => {
                return React.cloneElement(
                    child,
                    {
                        ...child.props,
                        className: classNames(child.props.className, styles.item)
                    }
                );
            })}
        </div>
    </section>
}
