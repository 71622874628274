export function sendAnalyticsEvent(action, category, label, value) {
    if (process.env.NODE_ENV === 'development') {
        console.log('Sending event', {action, category, label, value});
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: action,
        category,
        label,
        value
    });
}
