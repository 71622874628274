import * as styles from './CareersJobs.module.scss';
import React from 'react';
import classNames from 'classnames';
import {BlockTitle} from "../BlockTitle";
import {Typography} from "../Typography";
import {PageMainColumnWrapper} from "../PageMainColumnWrapper";
import {MarkdownContent} from "../MarkdownContent";
import {componentRef} from "../../functions/componentRef";
import {sendAnalyticsEvent} from "../../functions/sendAnalyticsEvent";
import {MarkdownRenderer} from "../MarkdownRenderer";
import {Block} from "../Block";

export function CareersJobs({title, content, jobs, ...otherProps}) {
    return <Block appearance={"subtle"}
                  {...otherProps}
                  {...componentRef('career-jobs')}>
        <PageMainColumnWrapper>
            <div className={styles.contentWrapper}>
                <BlockTitle tag="h2" className={styles.title}>{title}</BlockTitle>
                {content ?
                    <MarkdownRenderer bodyVariant="lead" className={styles.content} content={content}/> : undefined}
            </div>
            <div className={styles.jobs}>
                {jobs.map((x, index) => {
                    return <Job name={x.name}
                                key={index}
                                url={x.url}
                                content={x.description.data}/>;
                })}
            </div>
        </PageMainColumnWrapper>
    </Block>
}

function Job({name, content, url}) {
    return <a href={url}
              target="_blank"
              onMouseDown={() => {
                  sendAnalyticsEvent('link.job.click')
              }}
              rel="nofollow noopener noreferrer"
              {...componentRef.subcomponent('job')}
              className={styles.job}>
        <article className={styles.jobWrapper}>
            <h1 className={styles.jobName}>{name}</h1>
            <MarkdownContent component={Typography} content={content} className={styles.jobContent}/>
        </article>
    </a>

}
