import classNames from 'classnames';
import React from 'react';
import * as styles from './CareersHero.module.scss';
import {BlockTitle} from "../BlockTitle";
import {GatsbyImage} from "gatsby-plugin-image";
import {Breadcrumbs} from "../Breadcrumbs";
import {Typography} from "../Typography";
import {useIntl} from 'gatsby-plugin-react-intl';
import {componentRef} from "../../functions/componentRef";
import {MarkdownContent} from "../MarkdownContent";
import {MarkdownRenderer} from "../MarkdownRenderer";

export function CareersHero({
                                className,
                                breadcrumbTitle,
                                title,
                                firstImage,
                                secondImage,
                                content
                            }) {
    const intl = useIntl();
    return (
        <section className={classNames(className, styles.root)} {...componentRef('career-hero')}>
            <div className={styles.contentWrapper}>
                <Breadcrumbs className={styles.breadcrumbs}>
                    <Breadcrumbs.Item to="/">{intl.formatMessage({id: 'menu.home'})}</Breadcrumbs.Item>
                    <Breadcrumbs.Item>{breadcrumbTitle}</Breadcrumbs.Item>
                </Breadcrumbs>
                <BlockTitle className={styles.title}>{title}</BlockTitle>
                <MarkdownRenderer className={styles.content} content={content}/>
            </div>
            <div className={styles.images}>
                <GatsbyImage image={firstImage.gatsbyImageData} className={styles.imageFirst} alt="Careers image"/>
                <GatsbyImage image={secondImage.gatsbyImageData} className={styles.imageSecond} alt="Careers image"/>
            </div>
        </section>
    );
}
